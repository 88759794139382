import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { userServicer } from "../../../Commen_Services/user.services";
//import AadharImage from "../../../../assets/Images/Aadhar_front.png";
import { DocType } from "../../../../_enums/enum.services";
import PDFImage from "../../../../assets/Images/PDF_PNG.png";
import PDFImage1 from "../../../../assets/Images/certification.jpg";



interface propsHead {
    sendDataToParent: any;
    formData: any;
    sendErrorToParent: any;
    sendImagetoParent: any;
    DocumentData: any;
    errorData: any;
    isViewData: boolean;
}
const WorkInformation: React.FC<propsHead> = ({ sendDataToParent, formData, sendErrorToParent, errorData, isViewData, sendImagetoParent, DocumentData }) => {

    const [inputs, setInputs] = useState<any>({});
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [error, setError] = useState<any>({});
    const [workExperienceDoc, setworkExperienceDoc] = useState<any>(null);
    const [showDoc, setShowDoc] = useState<any>(null);
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];
    // const [error, setError] = useState({
    //     previous_Company_Name: '',
    //     previous_Job_Position: '',
    //     previous_Job_Location: '',
    //     previous_Job_Start_date: '',
    //     previous_Job_End_date: '',


    // });






    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let newInputs;
        newInputs = { ...inputs, [name]: value };

        let newError = { ...error };

        if (name === 'previous_Company_Name') {

            if (value !== "" && !/^[a-zA-Z0-9\s]{2,100}$/.test(value)) {
                newError.previous_Company_Name = "Please enter a valid Company name";
            }
            else {
                newError.previous_Company_Name = '';
            }
        }
        if (name === 'previous_Job_Location') {

            if (value !== "" && !/^[a-zA-Z0-9\s]{2,100}$/.test(value)) {
                newError.previous_Job_Location = "Please enter a valid Location";
            }
            else {
                newError.previous_Job_Location = '';
            }
        }


        // if (name === 'personal_Email') {

        //     if (!/^[a-zA-Z]{1,10}$/.test(value)) {
        //         newError.personal_Email = "Code should be Alphabetic & max 50 characters";
        //     }
        //     else {
        //         newError.personal_Email = '';
        //     }
        // }


        setInputs(newInputs);
        setError(newError);

    };

    const handleChangeforStartDate = ((event: any) => {

        debugger
        const name = event.target.name;
        const value = event.target.value;

        if(value == "")
        {
            setStartDate(value);
            if(endDate !== "")
            {
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: 'Please enter a valid date' }));
            }
            else
            {
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: 'Please enter a valid date' }));
            }   
        }    
        else if (!validateDate(value)) {
            setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: 'Please enter a valid date' }));
        }
        else {

            const startDateCheck = new Date(value);
            const endDateCheck = new Date(endDate);
            const ch = startDateCheck > endDateCheck;
            if (startDateCheck > endDateCheck) {
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: 'Please enter a valid date' }));
                setStartDate(value);
            }
            else {
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: '' }));
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_End_date: '' }));
                setInputs((values: any) => ({ ...values, [name]: value }));
                setStartDate(value);

            }

        }
    });

    const validateDate = (dateString: any) => {
        
        // if (!dateString) {
        //     return false;
        // }
        const inputDate = new Date(dateString);
        const today = new Date();
        if (inputDate > today) {
            return false;
        }
        return true;
    };

    const handleChangeforEndDate = ((event: any) => {
        debugger
        const name = event.target.name;
        const value = event.target.value;

        if(value == "")
        {
            setEndDate(value);
            setError((prevErrors: any) => ({ ...prevErrors, previous_Job_End_date: 'Please enter a valid date' }));
            if(startDate == "")
            {
                setStartDate(value);
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: '' }));
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_End_date: '' }));
            }
        }
        else if (!validateDate(value)) {
            setError((prevErrors: any) => ({ ...prevErrors, previous_Job_End_date: 'Please enter a valid date' }));
        }
        else {

            const startDateCheck = new Date(startDate);
            const endDateCheck = new Date(value);
            const ch = startDateCheck > endDateCheck;
            if(startDate == "")
            {
                setEndDate(value);
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: 'Please enter a valid date' }));
            }
            else if (startDateCheck > endDateCheck) {
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_End_date: 'Please enter a valid date' }));
            }
            else {
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_End_date: '' }));
                setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: '' }));
                setInputs((values: any) => ({ ...values, [name]: value }));
                setEndDate(value);
            }


        }

    });

    

    const handleWorkExperienceDoc = (e: any, documentType: any) => {

        const file = e.target.files[0];
        
        if (!ALLOWED_TYPES.includes(file.type)) {
            setError((prevErrors: any) => ({ ...prevErrors, workExperienceDoc: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else if (file.size > 10000000) {
            setError((prevErrors: any) => ({ ...prevErrors, workExperienceDoc: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else {
            if (file.type === 'application/pdf') {
                setShowDoc(file);
            }
            else
            {
                setShowDoc(null);
                setworkExperienceDoc(URL.createObjectURL(file));
                //setAadharFront(URL.createObjectURL(file));
            }
            setError((prevErrors: any) => ({ ...prevErrors, workExperienceDoc: "" }));
           
            let documentData = {
                fileData: file,
                docType: documentType,
                documentName: e.target.name
            }
            sendImagetoParent(documentData);
            //setDocumentList((values: any) => ([...values, { fileData: file, docType: documentType, documentName: e.target.name }]));
        }
    };






    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
        };
        let isDifferent = areObjectsEqual(inputs, formData);
        if (!isDifferent) {
            sendDataToParent(inputs);
        }

        // sendDataToParent(inputs)

    }, [inputs])

    useEffect(() => {
        sendErrorToParent(error);
    }, [error])

    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
        };

        let isDifferent = areObjectsEqual(error, errorData);
        


        if (!isDifferent) {
            // setInputsforChild(inputs);
            setError(errorData);
        }

    }, [errorData]);

    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
        };

        let isDifferent = areObjectsEqual(inputs, formData);

        if (!isDifferent) {
            // setInputsforChild(inputs);
            

            setInputs(formData);
            if (formData.previous_Job_Start_date !== null && formData.previous_Job_Start_date !== undefined) {
                const formattedDate = formData.previous_Job_Start_date.split('T')[0];
                setStartDate(formattedDate);

            }
            if (formData.previous_Job_End_date !== null && formData.previous_Job_End_date !== undefined) {
                const formattedDate = formData.previous_Job_End_date.split('T')[0];
                setEndDate(formattedDate);
            }
            if (formData.documentDetails !== null && formData.documentDetails !== undefined) {

                let documents = formData.documentDetails;
                
                documents.map((field: any) => {
                    if (field.documentTypeId === DocType.ExperienceDoc) {
                        if ((field.documentName as string).split('.').pop() === 'pdf') {
                            setShowDoc(field.documentPath);
                        }
                        else {
                            const file = field.documentPath;
                            setShowDoc(null);
                            setworkExperienceDoc(file);
                        }

                    }
                });

            }
        }

        // console.log(formData);

        // setInputs(formData);

    }, [formData])



    useEffect(() => {
        
        if (DocumentData.length > 0) {
            DocumentData.map((item: any) => {
                if (item.documentName === "workExperienceDoc") {
                    (item.fileData).type === 'application/pdf'
                      ? setShowDoc(item.fileData)
                      : (() => { setworkExperienceDoc(URL.createObjectURL(item.fileData)); setShowDoc(null); })();
                  }
                //item.documentName === "ExperienceDoc" && setworkExperienceDoc(URL.createObjectURL(item.fileData));
               // item.documentName === "workExperienceDoc" && (((item.fileData).type === 'application/pdf') ? setShowDoc(item.fileData) : setworkExperienceDoc(URL.createObjectURL(item.fileData)));

            });
        }
    }, [DocumentData])



    const OpenPDF = (file: any) => {
        
        let url = "/";
        if(typeof file === "string")
        {
            url = file;
        }
        else
        {
            url = URL.createObjectURL(file);
        }
        window.open(url, '_blank');
    }


    return (
        <>
            <div className="personal_Info">
                <div>Previous Work History</div>
                {/* <hr /> */}
                <div>
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="previous_Company_Name"
                                value={inputs.previous_Company_Name}
                                label="Company Name"
                                focused={!!inputs.previous_Company_Name}
                                onChange={handleChange}
                                error={!!error.previous_Company_Name}
                                helperText={error.previous_Company_Name}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.previous_Company_Name ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.previous_Company_Name ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.previous_Company_Name ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="previous_Job_Position"
                                value={inputs.previous_Job_Position}
                                label="Job Position"
                                focused={!!inputs.previous_Job_Position}
                                onChange={handleChange}
                                error={!!error.previous_Job_Position}
                                helperText={error.previous_Job_Position}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.previous_Job_Position ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.previous_Job_Position ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.previous_Job_Position ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name="previous_Job_Start_date"
                                type="date"
                                value={startDate}
                                label="Start Date"
                                onChange={handleChangeforStartDate}
                                focused={!!inputs.previous_Job_Start_date}
                                error={!!error.previous_Job_Start_date}
                                helperText={error.previous_Job_Start_date}
                                InputProps={{
                                    inputProps: {
                                        className: startDate ? '' : 'custom-date-input', 
                                      },
                                    readOnly: isViewData,
                                    style: {
                                        color: startDate ? '#243C5C' : 'transparent',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.previous_Job_Start_date ? 'red' : undefined
                                    },
                                    onInput: (event: any) => {

                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value })
                                            )

                                        }
                                    }
                                }}

                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.previous_Job_Start_date ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.previous_Job_Start_date ? '9px' : '12px',
                                        maxWidth: '210px'

                                    },
                                }}
                            />



                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name="previous_Job_End_date"
                                type="date"
                                value={endDate}
                                label="End Date"
                                onChange={handleChangeforEndDate}
                                focused={!!inputs.previous_Job_End_date}
                                error={!!error.previous_Job_End_date}
                                helperText={error.previous_Job_End_date}
                                InputProps={{
                                    inputProps: {
                                        className: endDate ? '' : 'custom-date-input', 
                                      },
                                    readOnly: isViewData,
                                    style: {
                                        color: endDate ? '#243C5C' : 'transparent',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.previous_Job_End_date ? 'red' : undefined
                                    },
                                    onInput: (event: any) => {

                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value })
                                            )

                                        }
                                    }
                                }}

                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.previous_Job_End_date ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.previous_Job_End_date ? '9px' : '12px',
                                        maxWidth: '210px'

                                    },
                                }}
                            />



                        </div>

                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="previous_Job_Location"
                                value={inputs.previous_Job_Location}
                                label="Location"
                                focused={!!inputs.previous_Job_Location}
                                onChange={handleChange}
                                error={!!error.previous_Job_Location}
                                helperText={error.previous_Job_Location}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.previous_Job_Location ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.previous_Job_Location ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.previous_Job_Location ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>







                    </div>
                    <div>
                        {/* <div className="Profile_Pic_div" style={{marginTop: '30px'}}>
                            <div className="Aadhar_img">
                                {workExperienceDoc ? (
                                    <img
                                        src={workExperienceDoc}
                                        width="150"
                                        className="rounded-circle imaje"
                                        alt="Selected"
                                    />
                                ) : (
                                    <img
                                        src={AadharImage}
                                        width="100"
                                        className="rounded-circle imaje"
                                        alt="Default"
                                    />
                                )}
                            </div>
                            <div className="prfoile_upload">

                                <label
                                    htmlFor="handleworkExperienceDoc"
                                    className="upload_btn"
                                    style={{ fontSize: "14px", justifyContent: 'center', width: '200px' }}
                                >
                                    Upload&nbsp;Document&nbsp;
                                </label>
                                <input
                                    id="handleworkExperienceDoc"
                                    type="file"
                                    name="workExperienceDoc"
                                    disabled={isViewData}
                                    className="upload-input"
                                    style={{ display: 'none' }}
                                    //accept="image/*"
                                    accept=".jpg, .png, .pdf"
                                    onChange={(event) => handleWorkExperienceDoc(event, DocType.ExperienceDoc)}
                                />
                            </div>
                            <div style={{width: '200px'}}>
                                {(error.workExperienceDoc != '' || error.workExperienceDoc != '' ) && <span style={{ color: 'red', fontSize: '9px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>{error.workExperienceDoc}</span>}
                            </div>
                        </div>
                    </div> */}
                        <div className="Profile_dtl" style={{ marginLeft: 80, marginTop: 10 }}>
                            <div className="Profile_Pic_div">
                                <div className="Aadhar_img">
                                    {
                                        showDoc ?
                                            (
                                                <div>
                                                    <img
                                                        src={PDFImage}
                                                        width="100"
                                                        className="rounded-circle imaje"
                                                        alt="Selected"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => OpenPDF(showDoc)} 
                                                        //onClick={() => OpenPDF(typeof showDoc === "string" ? URL.createObjectURL(showDoc as any) : showDoc)} 
                                                        //onClick={() => OpenPDF((formData.documentDetails !== null && formData.documentDetails !== undefined) ? (typeof showDoc === "string" ? URL.createObjectURL(showDoc as any) : showDoc) : URL.createObjectURL(showDoc))}
                                                    />

                                                </div>
                                            )
                                            :

                                            workExperienceDoc ? (
                                                <img
                                                    src={workExperienceDoc}
                                                    width="150"
                                                    className="rounded-circle imaje"
                                                    alt="Selected"
                                                />
                                            ) : (
                                                <img
                                                    src={PDFImage1}
                                                    width="100"
                                                    height="80"
                                                    className="rounded-circle imaje"
                                                    alt="Default"
                                                />
                                            )}
                                </div>
                                <div className="prfoile_upload">

                                    <label
                                        htmlFor="handleworkExperienceDoc"
                                        className="upload_btn"
                                        style={{ fontSize: "14px", justifyContent: 'center', width: '200px' }}
                                    >
                                        Upload&nbsp;Document&nbsp;
                                    </label>
                                    <input
                                        id="handleworkExperienceDoc"
                                        type="file"
                                        name="workExperienceDoc"
                                        disabled={isViewData}
                                        className="upload-input"
                                        style={{ display: 'none' }}
                                        //accept="image/*"
                                        accept=".jpg, .png, .pdf"
                                        onChange={(event) => handleWorkExperienceDoc(event, DocType.ExperienceDoc)}
                                    />
                                </div>
                                <div style={{ width: '200px' }}>
                                    {(error.workExperienceDoc != '' || error.workExperienceDoc != '') && <span style={{ color: 'red', fontSize: '9px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', width: '100%' }}>{error.workExperienceDoc}</span>}
                                </div>

                            </div>
                           
                        </div>
                    </div>








                </div>
            </div>
        </>
    )
}

export default WorkInformation;