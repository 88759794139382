import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { userServicer } from "../../../Commen_Services/user.services";
import AadharImage from "../../../../assets/Images/Aadhar_front.png";
import PanImage from "../../../../assets/Images/PANCard.png";
import { DocType } from "../../../../_enums/enum.services";
import PDFImage from "../../../../assets/Images/PDF_PNG.png";



interface propsHead {
    sendDataToParent: any;
    formData: any;
    sendImagetoParent: any;
    DocumentData: any;
    sendErrorToParent: any;
    errorData: any;
    isViewData: boolean;
}
const Identification: React.FC<propsHead> = ({ sendDataToParent, formData, sendImagetoParent, DocumentData, sendErrorToParent, errorData, isViewData }) => {

    const [inputs, setInputs] = useState<any>({});
    const [error, setError] = useState<any>({});
    // const [error, setError] = useState({
    //     nameOnAdharcard: '',
    //     adharCardNo: '',
    //     nameOnPanCard: '',
    //     pancardNo: ''

    // });
    const [aadharFront, setAadharFront] = useState<any>(null);
    const [aadharBack, setAadharBack] = useState<any>(null);
    const [selectedPanImage, setPANImage] = useState<any>(null);
    const ALLOWED_TYPES = ['image/jpeg', 'image/png','application/pdf'];
    const [showDoc, setShowDoc] = useState<any>(null);
    const [showDocforAadharBack, setShowDocforAadharBack] = useState<any>(null);
    const [showDocPanImage, setShowDocPanImage] = useState<any>(null);




    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let newInputs;
        newInputs = { ...inputs, [name]: value };

        let newError = { ...error };

        if (name === 'nameOnAdharcard') {

            if (value !== "" && !/^[a-zA-Z0-9\s]{2,100}$/.test(value)) {
                newError.nameOnAdharcard = "Please Enter Valid Aadhar Name";
            }
            else {
                newError.nameOnAdharcard = '';
            }
        }
        if (name === 'nameOnPanCard') {

            if (value !== "" && !/^[a-zA-Z0-9\s]{2,100}$/.test(value)) {
                newError.nameOnPanCard = "Please Enter Valid PAN Name";
            }
            else {
                newError.nameOnPanCard = '';
            }
        }
        if (name === 'adharCardNo') {
            if (value !== "" && !/^[0-9]{12}$/.test(value)) {
                newError.adharCardNo = "Please enter a valid Aadhar Number";
            }
            else {
                newError.adharCardNo = '';
            }
        }
        if (name === 'pancardNo') {
            if (value !== "" && !/^[A-Z0-9]{10}$/.test(value)) {
                newError.pancardNo = "Please enter a valid PAN Number";
            }
            else {
                newError.pancardNo = '';
            }
        }



        setInputs(newInputs);
        setError(newError);

    };

    const handleAadharFront = (e: any, documentType: any) => {

        const file = e.target.files[0];
        
        if (!ALLOWED_TYPES.includes(file.type)) {
            setError((prevErrors: any) => ({ ...prevErrors, AadharFront: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else if (file.size > 10000000) {
            setError((prevErrors: any) => ({ ...prevErrors, AadharFront: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else {
            if (file.type === 'application/pdf') {
                setShowDoc(file);
            }
            else
            {
                setShowDoc(null);
                setAadharFront(URL.createObjectURL(file));
            }
            setError((prevErrors: any) => ({ ...prevErrors, AadharFront: "" }));
           
            let documentData = {
                fileData: file,
                docType: documentType,
                documentName: e.target.name
            }
            sendImagetoParent(documentData);
            //setDocumentList((values: any) => ([...values, { fileData: file, docType: documentType, documentName: e.target.name }]));
        }
    };

    const handleAadharBack = (e: any, documentType: any) => {

        const file = e.target.files[0];

        if (!ALLOWED_TYPES.includes(file.type)) {
            setError((prevErrors: any) => ({ ...prevErrors, AadharBack: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else if (file.size > 10000000) {
            setError((prevErrors: any) => ({ ...prevErrors, AadharBack: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else {
            if (file.type === 'application/pdf') {
                setShowDocforAadharBack(file);
            }
            else
            {
                setShowDocforAadharBack(null);
                setAadharBack(URL.createObjectURL(file));
            }
            setError((prevErrors: any) => ({ ...prevErrors, AadharBack: "" }));

           
            //setDocumentList((values: any) => ([...values, { fileData: file, docType: documentType, documentName: e.target.name }]));
            let documentData = {
                fileData: file,
                docType: documentType,
                documentName: e.target.name
            }
            sendImagetoParent(documentData);
        }
    };

    const handlePanImage = (e: any, documentType: any) => {

        const file = e.target.files[0];
        if (!ALLOWED_TYPES.includes(file.type)) {
            setError((prevErrors: any) => ({ ...prevErrors, PANImage: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else if (file.size > 10000000) {
            setError((prevErrors: any) => ({ ...prevErrors, PANImage: "File Should be in JPG, JPEG, PNG, or PDF format and not exceed 10 MB" }));

            return;
        }
        else {
            if (file.type === 'application/pdf') {
                setShowDocPanImage(file);
            }
            else
            {
                setShowDocPanImage(null);
                setPANImage(URL.createObjectURL(file));
            }
            setError((prevErrors: any) => ({ ...prevErrors, PANImage: "" }));

            
            //setDocumentList((values: any) => ([...values, { fileData: file, docType: documentType, documentName: e.target.name }]));
            let documentData = {
                fileData: file,
                docType: documentType,
                documentName: e.target.name
            }
            sendImagetoParent(documentData);
        }
    };

    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
        };
        let isDifferent = areObjectsEqual(inputs, formData);
        if (!isDifferent) {
            sendDataToParent(inputs);
        }

        // sendDataToParent(inputs)

    }, [inputs])

    useEffect(() => {
        sendErrorToParent(error);
    }, [error])


    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
        };

        let isDifferent = areObjectsEqual(error, errorData);


        if (!isDifferent) {
            // setInputsforChild(inputs);
            setError(errorData);
        }

    }, [errorData]);

    useEffect(() => {
        
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
        };

        let isDifferent = areObjectsEqual(inputs, formData);

        if (!isDifferent) {

            setInputs(formData);
            if (formData.documentDetails !== null && formData.documentDetails !== undefined) {

                let documents = formData.documentDetails;
                
                documents.map((field: any) => {
                    if (field.documentTypeId === DocType.AadharFront) {
                        if ((field.documentName as string).split('.').pop() === 'pdf') {
                            setShowDoc(field.documentPath);
                        }
                        else {
                            const file = field.documentPath;
                            setShowDoc(null);
                            setAadharFront(file);
                        }

                    }
                    if (field.documentTypeId === DocType.AadharBack) {
                        if ((field.documentName as string).split('.').pop() === 'pdf') {
                            setShowDocforAadharBack(field.documentPath);
                        }
                        else {
                            const file = field.documentPath;
                            setShowDocforAadharBack(null);
                            setAadharBack(file);
                        }
                    }
                    if (field.documentTypeId === DocType.PANCard) {
                        if ((field.documentName as string).split('.').pop() === 'pdf') {
                            setShowDocPanImage(field.documentPath);
                        }
                        else {
                            const file = field.documentPath;
                            setShowDocPanImage(null);
                            setPANImage(file);
                        }
                    }
                });

            }
        }


    }, [formData])

    useEffect(() => {
        

        // if (DocumentData.length > 0) {
        //     DocumentData.map((item: any) => {

        //         item.documentName === "AadharFront" && (((item.fileData).type === 'application/pdf') ? setShowDoc(item.fileData) : () => {setAadharFront(URL.createObjectURL(item.fileData)); setShowDoc(null)});
        //         item.documentName === "AadharBack" && (((item.fileData).type === 'application/pdf') ? setShowDocforAadharBack(item.fileData) : () => {setAadharBack(URL.createObjectURL(item.fileData)); setShowDocforAadharBack(null)});
        //         item.documentName === "PANImage" && (((item.fileData).type === 'application/pdf') ? setShowDocPanImage(item.fileData) : setPANImage(URL.createObjectURL(item.fileData)));

        //     });
        // }

        if (DocumentData.length > 0) {
            DocumentData.map((item: any) => {
              if (item.documentName === "AadharFront") {
                (item.fileData).type === 'application/pdf'
                  ? setShowDoc(item.fileData)
                  : (() => { setAadharFront(URL.createObjectURL(item.fileData)); setShowDoc(null); })();
              }
              if (item.documentName === "AadharBack") {
                (item.fileData).type === 'application/pdf'
                  ? setShowDocforAadharBack(item.fileData)
                  : (() => { setAadharBack(URL.createObjectURL(item.fileData)); setShowDocforAadharBack(null); })();
              }
              if (item.documentName === "PANImage") {
                (item.fileData).type === 'application/pdf'
                  ? setShowDocPanImage(item.fileData)
                  : (() => { setPANImage(URL.createObjectURL(item.fileData)); setShowDocPanImage(null); })();
              }
            });
          }
    }, [DocumentData])

    const OpenPDF = (file: any) => {
        
        
        let url = "/";
        if(typeof file === "string")
        {
            url = file;
        }
        else
        {
            url = URL.createObjectURL(file);
        }
        window.open(url, '_blank');
    }
    return (
        <>
            <div className="personal_Info">
                <div>Aadhar Card Details</div>
                {/* <hr /> */}
                <div>
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="nameOnAdharcard"
                                value={inputs.nameOnAdharcard}
                                label="Name As Per Aadhar Card"
                                focused={!!inputs.nameOnAdharcard}
                                onChange={handleChange}
                                error={!!error.nameOnAdharcard}
                                helperText={error.nameOnAdharcard}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.nameOnAdharcard ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.nameOnAdharcard ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.nameOnAdharcard ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="adharCardNo"
                                type="number"
                                sx = {{
                                    "input::-webkit-inner-spin-button" : {
                                    "-webkit-appearance": "none",
                                    "margin": "0"
                                }
                                }}
                                value={inputs.adharCardNo}
                                label="Aadhar Card Number"
                                onKeyDown={(evt) => ["e", "E", "+", "-",".","/"].includes(evt.key) && evt.preventDefault()}
                                focused={!!inputs.adharCardNo}
                                onChange={handleChange}
                                error={!!error.adharCardNo}
                                helperText={error.adharCardNo}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.adharCardNo ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.adharCardNo ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.adharCardNo ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="Profile_dtl" style={{ marginLeft: 80, marginTop: 10 }}>
                        <div className="Profile_Pic_div">
                            <div className="Aadhar_img">
                                {
                                    showDoc ?
                                        (
                                            <div>
                                                <img
                                                    src={PDFImage}
                                                    width="100"
                                                    className="rounded-circle imaje"
                                                    alt="Selected"
                                                    style={{cursor: 'pointer'}}
                                                   // onClick={() => OpenPDF((formData.documentDetails !== null && formData.documentDetails !== undefined) ? showDoc : URL.createObjectURL(showDoc))}
                                                    //onClick={() => OpenPDF((formData.documentDetails !== null && formData.documentDetails !== undefined) ? (typeof showDoc === "string" ? URL.createObjectURL(showDoc as any) : showDoc) : URL.createObjectURL(showDoc))}
                                                    onClick={() => OpenPDF(showDoc)} 
                                                />

                                            </div>
                                        )
                                        :

                                        aadharFront ? (
                                            <img
                                                src={aadharFront}
                                                width="150"
                                                className="rounded-circle imaje"
                                                alt="Selected"
                                            />
                                        ) : (
                                            <img
                                                src={AadharImage}
                                                width="100"
                                                className="rounded-circle imaje"
                                                alt="Default"
                                            />
                                        )}
                            </div>
                            <div className="prfoile_upload">

                                <label
                                    htmlFor="handleAadharFront"
                                    className="upload_btn"
                                    style={{ fontSize: "14px", justifyContent: 'center', width: '200px' }}
                                >
                                    Upload&nbsp;AadharCard&nbsp;Front
                                </label>
                                <input
                                    id="handleAadharFront"
                                    type="file"
                                    name="AadharFront"
                                    disabled={isViewData}
                                    className="upload-input"
                                    style={{ display: 'none' }}
                                    //accept="image/*"
                                    accept=".jpg, .png, .pdf"
                                    onChange={(event) => handleAadharFront(event, DocType.AadharFront)}
                                />
                            </div>
                            <div style={{ width: '200px' }}>
                                {(error.AadharFront != '' || error.AadharBack != '') && <span style={{ color: 'red', fontSize: '9px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', width: '100%' }}>{error.AadharFront}</span>}
                            </div>

                        </div>
                        <div className="Profile_Pic_div">
                            <div className="Aadhar_img">
                                {
                                
                                showDocforAadharBack ?
                                        (
                                            <div>
                                                <img
                                                    src={PDFImage}
                                                    width="100"
                                                    className="rounded-circle imaje"
                                                    style={{cursor: 'pointer'}}
                                                    alt="Selected"
                                                    //onClick={() => OpenPDF((formData.documentDetails !== null && formData.documentDetails !== undefined) ? showDocforAadharBack : URL.createObjectURL(showDoc))}
                                                    //onClick={() => OpenPDF((formData.documentDetails !== null && formData.documentDetails !== undefined) ? (typeof showDocforAadharBack === "string" ? URL.createObjectURL(showDocforAadharBack as any) : showDocforAadharBack) : URL.createObjectURL(showDocforAadharBack))}
                                                    onClick={() => OpenPDF(showDocforAadharBack)} 
                                                />

                                            </div>
                                        ) 
                                :
                                
                                aadharBack ? (
                                    <img
                                        src={aadharBack}
                                        width="150"
                                        className="rounded-circle imaje"
                                        alt="Selected"
                                    />
                                ) : (
                                    <img
                                        src={AadharImage}
                                        width="100"
                                        className="rounded-circle imaje"
                                        alt="Default"
                                    />
                                )}
                            </div>
                            <div className="prfoile_upload">

                                <label
                                    htmlFor="handleAadharBack"
                                    className="upload_btn"
                                    style={{ fontSize: "14px", justifyContent: 'center', width: '200px' }}
                                >
                                    Upload&nbsp;AadharCard&nbsp;Back
                                </label>
                                <input
                                    id="handleAadharBack"
                                    type="file"
                                    name="AadharBack"
                                    disabled={isViewData}
                                    className="upload-input"
                                    style={{ display: 'none' }}
                                    //accept="image/*"
                                    accept=".jpg, .png, .pdf"
                                    onChange={(event) => handleAadharBack(event, DocType.AadharBack)}
                                />
                            </div>
                            <div style={{ width: '200px' }}>
                                {(error.AadharFront != '' || error.AadharBack != '') && <span style={{ color: 'red', fontSize: '9px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>{error.AadharBack}</span>}
                            </div>

                        </div>
                    </div>


                </div>
                <div style={{ marginTop: 30 }}>PAN Card Details</div>
                <div >
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="nameOnPanCard"
                                value={inputs.nameOnPanCard}
                                label="Name As Per PAN Card"
                                focused={!!inputs.nameOnPanCard}
                                onChange={handleChange}
                                error={!!error.nameOnPanCard}
                                helperText={error.nameOnPanCard}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.nameOnPanCard ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.nameOnPanCard ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.nameOnPanCard ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="pancardNo"
                                value={inputs.pancardNo}
                                label="PAN Card Number"
                                focused={!!inputs.pancardNo}
                                onChange={handleChange}
                                error={!!error.pancardNo}
                                helperText={error.pancardNo}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.pancardNo ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.pancardNo ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.pancardNo ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>





                    </div>
                    <div className="Profile_dtl" style={{ marginLeft: 80, marginTop: 10 }}>
                        <div className="Profile_Pic_div">
                            <div className="Aadhar_img">
                                {
                                
                                showDocPanImage ?
                                        (
                                            <div>
                                                <img
                                                    src={PDFImage}
                                                    width="100"
                                                    className="rounded-circle imaje"
                                                    style={{cursor: 'pointer'}}
                                                    alt="Selected"
                                                    //onClick={() => OpenPDF((formData.documentDetails !== null && formData.documentDetails !== undefined) ? showDocPanImage : URL.createObjectURL(showDoc))}
                                                    //onClick={() => OpenPDF((formData.documentDetails !== null && formData.documentDetails !== undefined) ? (typeof showDocPanImage === "string" ? URL.createObjectURL(showDocPanImage as any) : showDoc) : URL.createObjectURL(showDocPanImage))}
                                                    onClick={() => OpenPDF(showDocPanImage)} 
                                                />

                                            </div>
                                        )
                                :
                                
                                
                                selectedPanImage ? (
                                    <img
                                        src={selectedPanImage}
                                        width="150"
                                        className="rounded-circle imaje"
                                        alt="Selected"
                                    />
                                ) : (
                                    <img
                                        src={PanImage}
                                        width="100"
                                        className="rounded-circle imaje"
                                        alt="Default"
                                    />
                                )}
                            </div>
                            <div className="prfoile_upload">

                                <label
                                    htmlFor="handlePanImage"
                                    className="upload_btn"
                                    style={{ fontSize: "14px", justifyContent: 'center', width: '200px' }}
                                >
                                    Upload&nbsp;PAN&nbsp;Card
                                </label>
                                <input
                                    id="handlePanImage"
                                    type="file"
                                    name="PANImage"
                                    disabled={isViewData}
                                    className="upload-input"
                                    style={{ display: 'none' }}
                                    //accept="image/*"
                                    accept=".jpg, .png, .pdf"
                                    onChange={(event) => handlePanImage(event, DocType.PANCard)}
                                />
                            </div>
                            <div style={{ width: '200px' }}>
                                {error.PANImage != '' && <span style={{ color: 'red', fontSize: '9px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>{error.PANImage}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Identification;