// export const  AccessLevel =  {

//     Selection: -6,
//     Back: -4,
//     Discard: -3,
//     SaveandNew: -2,
//     Save: -1,
//     None: 0,
//     View : 1,
//     New : 2,
//     Edit : 4,
//     Delete : 8,
//     Print : 16,
//     Export : 32,
//     Send : 64,
//     ViewHistory: 128,
  
//   };

export const  AccessLevel =  {

  
  View : 1,
  New : 2,
  Edit : 4,
  Delete : 8,
  Filter : 16,
  Export : 32,
  Send : 64,
  ViewHistory: 128,
  AddNew: 256,
  EditNew: 512,
  ViewNew:1024,
  Print : 2048,
};

export const InputType = {
    None : 0,
    Text : 1,
    Email : 2,
    Phone : 3,
    Mobile :4,
    Number : 5,
    Date : 6,
    IsActive : 7,
    Amount : 8,
    Dropdown : 9,
    HTML : 10,
    Index : 11,
    DateTime : 12,
    SubReport : 13,
    MultiSelect :14,
};

export const PageEnums = {

  Headquarter: 4,
  Designation: 5,
  State: 6,
  City: 7,
  AreaList: 8,
  Employee:9, 
};


export const MasterPrimaryKeys = {
  Headquarter: "hqId",
  Employee: "employeeId",
}


export const DocType = {
  PANCard: 1,
  AadharFront : 2,
  AadharBack: 3,
  EmployeeProfile: 4,
  ExperienceDoc: 5,

}

