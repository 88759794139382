import { commanServicer } from "../Commen_Services/common.services";
// import axios from "axios";

async function getUserMenu() {

    return await commanServicer.fetchRequest("", true, 'UserMaster/GetUserMenu', 'GET');
}

async function login(loginData: any) {

    return await commanServicer.fetchRequest(JSON.stringify(loginData), false, 'UserMaster/login', 'POST');
}

async function  logOut() {
    return await commanServicer.fetchRequest('', true, 'UserMaster/logout', 'POST');
}

async function tableConfig(pageID: any) {
    return await commanServicer.fetchRequest('', true, 'Report/tblconfig?PageId=' + pageID, 'GET');
}

async function reportConfig(pageID: any) {
    return await commanServicer.fetchRequest('', true, 'Report/reportConfig?PageId=' + pageID, 'GET');
}

async function getDropDownlist(accessKey: any, id: any) {
    if (id != null) {
        return await commanServicer.fetchRequest('', true, 'common/getlist?Sp_Status=' + accessKey+'&id='+id, 'GET');
    }
    else {
        return await commanServicer.fetchRequest('', true, 'common/getlist?Sp_Status=' + accessKey, 'GET');
    }

}



async function getReportlist(pageID: number, fillData: any, paginationData: any) {
    
    
    //{key: 'hqCode', value: 'HQAPANA00001'}]
    const data1 = {
        orderBy: "",
        pageNumber: paginationData !== undefined ? paginationData.pageNumber : 1,
        pageSize: paginationData !== undefined ? paginationData.pageSize : 10,
        isMultiFilter: false,
        pageId: pageID,
        filterArray: fillData,
        isExport: true,

    }

    const requestBody = JSON.stringify(data1);
    return await commanServicer.fetchRequest(requestBody, true, 'Report/getreportlist', 'POST');
}





// async function getReportData(pageID : any)
// { const data1 = {
//     orderBy: "",
//     pageNumber: "1",
//     pageSize: "10",
//     isMultiFilter: false,
//     pageId: pageID,
//     filterArray: [],
//     example: ""
//   }
//     const API = axios.create({ baseURL : `http://localhost:1437/api/` });
//     return await   API.POST('Report/getreportlist',data1);
// }

export const userServicer = {
    getUserMenu,
    login,
    tableConfig,
    reportConfig,
    getReportlist,
    getDropDownlist,
    logOut
}