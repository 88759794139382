import { Add, Close, Save } from "@mui/icons-material";
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import 'react-toastify/dist/ReactToastify.css';

import customToast from "../../Commen_Component/Toast/CustomToast";
import { userServicer } from "../../Commen_Services/user.services";
import { CityMasterService } from "../../Commen_Services/CityMaster.service";
import Popup from "reactjs-popup";


interface propsHead {
    columndata: any[];
    editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;

}

interface RowData {
    [key: string]: any;
}

const AddCity: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {



    const [inputs, setInputs] = useState<any>({});

    const [disable, setDisable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [error, setError] = useState({
        city_Name: '',
        city_Code: '',
        countryId: '',
        stateId: ''
    });
    const [use, setUse] = useState(true);
    const [isSave, setIssave] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsforState, setOptionsforState] = useState([{ OptionID: 1, OptionName: "--Select State--" }]);
    const [selectedCoutnry, setSelectedCountry] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [handleCancel, setCancel] = useState(false);
    const [visibleUPdate, setVisibleUpdate] = useState(false);






    useEffect(() => {
        const fetchState = async () => {
      
            let statListdata = await userServicer.getDropDownlist("StateMaster", Number(selectedCoutnry))

            let statList = statListdata.data;
            const transformedData1 = statList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(statList[0])[0]],
                OptionName: item[Object.keys(statList[0])[1]] === undefined ? item[Object.keys(statList[0])[2]] : item[Object.keys(statList[0])[1]]
            }));

            setOptionsforState(transformedData1);

        }


        if (!!selectedCoutnry) {
            fetchState();
        }
    }, [selectedCoutnry])


    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        // setInputs(values => ({...values, [name]: value}))
        let newInputs;
        let newError = { ...error };
        if (name === 'stateId') {

            const value1 = parseInt(value);
            newInputs = { ...inputs, [name]: value1 };
            
            
            if (value1 > 100) {
                newError.stateId = "Maximum of 2 digits";
            }
            else if (value1 < 0) {
                newError.stateId = "LevelId must be positive";
            }
            else {
                newError.stateId = '';
            }
        }
        else {
            newInputs = { ...inputs, [name]: value };
        }

        

        if (name === 'city_Name') {
            if (!/^[a-zA-Z0-9]{1,50}$/.test(value)) {
                newError.city_Name = "Please enter a unique alphanumeric city name & Maximum length of 50 characters";
            } else {

                // let data = [{key : name, value: value}]
                // const data1 = await userServicer.getReportlist(PageEnums.City,data,undefined);
                // console.log(data1);
                newError.city_Name = '';
            }
        }
        if (name === 'city_Code') {

            if (!/^[a-zA-Z0-9]{1,10}$/.test(value)) {
                newError.city_Code = "Please enter a unique alphanumeric city code & Maximum length of 10 characters";
            }
            else {
                newError.city_Code = '';
            }
        } 
        if (name === 'stateId') 
        {
            
        }

        // Update state
        setInputs(newInputs);
        setError(newError);
        setVisibleUpdate(false);

    };


    const handleFocus = (fieldName: any) => {

        if (!inputs[fieldName]) {
            if(fieldName === 'city_Code')
            {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric City Code " }));

            }
            if(fieldName === 'city_Name')
            {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric City Name " }));

            }
        }
    };

    // const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const handelSubmit = (event: any, isSav: any) => {
        event.preventDefault();
        if (!inputs.city_Name) {
            setError((prevErrors) => ({ ...prevErrors, city_Name: "Please enter a unique alphanumeric City Name" }));
        }
        if (!inputs.city_Code) {
            setError((prevErrors) => ({ ...prevErrors, city_Code: "Please enter a unique alphanumeric City Code" }));
        }
        if (!inputs.stateId) {
            setError((prevErrors) => ({ ...prevErrors, stateId: "Please Select State" }));
        }
        if (!selectedCoutnry) {
            setError((prevErrors) => ({ ...prevErrors, countryId: "Please Select Country" }));
        }

        if (inputs.city_Name !== undefined && inputs.city_Code !== undefined && inputs.stateId !== undefined) {
            if (error.city_Name === '' && error.city_Code === "" && error.stateId === "") {
                try {

                    // console.log(inputs);
                    CityMasterService.SaveorUpdate(inputs).then(user => {
                        
                        if (user.statusCode === 200) {
                            if (user.status === false) {
                                customToast.Warning(user.message);
                                closeForm(isSav);
                                refreshGrid(true);
                            }
                            else {
                                customToast.success(user.message);
                                closeForm(isSav);
                                refreshGrid(true);


                            }
                            //customToast.showToast("Warning: Something went wrong", "warning");

                            // setInputs({});
                            // setDisable(true);
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }



        }


    }

    const handleChangeMUIforCountry = (event: any) => {
        
        const name = event.target.name;
        const value = event.target.value;
        // console.log(name + ":" + value);
        setSelectedCountry(value);
        setError((prevErrors) => ({ ...prevErrors, countryId: '' }));
        setVisibleUpdate(false);
        //  setInputs((values: any ) => ({ ...values, [name]: value }))

    }

    const handleChangeMUI = (event: any) => {
        
        const name = event.target.name;
        const value = event.target.value;
   
        setError((prevErrors) => ({ ...prevErrors, stateId: '' }));
        setInputs((values: any) => ({ ...values, [name]: value }))


        //setSelectedField(values => ({ ...values, [name]: value }));
    }

    const closeForm = (isSav: any) => {

        setInputs({ state_Id: 0, isActive: true, city_Code: '', city_Name: '', stateId: '', isViewData: false });
        setError({
            city_Code: '',
            city_Name: '',
            countryId: '',
            stateId: ''
        });
        setSelectedCountry('');
        sendDatatoParent("");
        sendViewStatus(false);
        if (isSav) {
            setDisable(true);
            setEditable(true);
           
        }

    }

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        
        setIssave(true);
        setShowConfirmation(false);
        handleCancel ? setCancel(false) : setCancel(true);
       


    };

    useEffect(() => {
        setVisibleUpdate(false);
        closeForm(true);


    }, [handleCancel])



    useEffect(() => {
        

        const fetchStateById = async (city_ID: Number) => {
            let data = await CityMasterService.getcitydatabyid(city_ID);
            editRowData = data.data;
            // console.log(editRowData);

            
            setInputs(editRowData);
            
            setSelectedCountry((editRowData as any).countryId);

        }

        setInputs((values: any) => ({ ...values, city_Id: 0, isActive: true }));
        if ((editRowData as any) !== "") {

            fetchStateById((editRowData as any).city_Id);
            // setInputs(editRowData);
            setDisable(false);
            setVisibleUpdate(true);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }



    }, [editRowData, use])



    useEffect(() => {
        const fetcOption = async () => {
            
            try{
                const data = await userServicer.getDropDownlist("Country", null);
                const h = data.data;
                const transformedData = h.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(h[0])[0]],
                    OptionName: item[Object.keys(h[0])[1]] === undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
                }));
                setOptions(transformedData);
            }
            catch
            {
                console.log("error While fetchiung Dropdown");
                
            }
           
        }

        fetcOption();


    }, [])

    let valueofState = { OptionName: '--Select State--' };

    if (options !== undefined && options !== null && inputs["stateId"] !== undefined && inputs["stateId"] !== null) {
        let val2 = optionsforState.filter(item => item.OptionID === inputs["stateId"]);

        let val4 = val2.length > 0 ? Object.values(val2[0]) : '';

        valueofState.OptionName = val4[1] !== undefined ? String(val4[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }





    return (<>
        <div style={{ marginLeft: 7, marginBottom: -3 }}>
            

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <form onSubmit={handelSubmit} style={{ display: 'flex', flexDirection: 'row' }} > */}


                    <TextField

                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="city_Code"
                        value={inputs["city_Code"] ? inputs["city_Code"] : ''}
                        label="City Code"
                        disabled={disable}
                        onChange={handleChange}
                        focused={!!inputs.city_Code}
                        onFocus={() => handleFocus("city_Code")}
                        error={!!error.city_Code}
                        helperText={error.city_Code}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.city_Code ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.city_Code ? 'red' : undefined
                            },
                        }}
                        FormHelperTextProps={{
                            style: {
                                fontSize: error.city_Code ? '9px' : '12px',
                                maxWidth: '210px'


                            },
                        }}
                    />
                    <TextField
                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="city_Name"
                        disabled={disable}
                        value={inputs["city_Name"] ? inputs["city_Name"] : ''}
                        label="City Name"
                        onChange={handleChange}
                        focused={!!inputs.city_Name}
                        onFocus={() => handleFocus('city_Name')}
                        error={!!error.city_Name}
                        helperText={error.city_Name}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.city_Name ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{

                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.city_Name ? 'red' : undefined
                            },
                        }}

                        FormHelperTextProps={{
                            style: {
                                fontSize: error.city_Name ? '9px' : '12px',
                                maxWidth: '210px'


                            },
                        }}

                    />
                    
                
                    <FormControl focused={!!inputs.countryId} variant="standard"  sx={{ m: -0.3, width: 180, minWidth: 120, marginRight: 1 }}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{
                            fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                        }}>Country</InputLabel>
                        <Select
                            readOnly={editable}
                            disabled={disable}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            autoComplete="inside"
                            name="countryId"
                            error={!!error.countryId}
                            //value={inputs.countryId == null ? null : Number(inputs.countryId)}
                            value={selectedCoutnry}
                            onChange={handleChangeMUIforCountry}
                            label="Search c"
                            onClose={(e) => e.preventDefault()}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300,
                                        overflowY: 'auto',
                                    },
                                },
                            }}
                        >
                            {options.map((item: any) => (
                                <MenuItem key={item.OptionID} value={item.OptionID}>
                                    {item.OptionName}
                                </MenuItem>
                            ))}
                        </Select>
                        {error.countryId ? (
                            <FormHelperText sx={{ color: 'red', fontSize: 9,maxWidth: '210px'
                            }}>{error.countryId}</FormHelperText>
                        ) : (
                            <FormHelperText sx={{ color: 'white', fontSize: 9 }}>Helper Text</FormHelperText>
                        )}
                    </FormControl>


                   
                    <FormControl>
                        <Autocomplete
                            disabled={disable}
                            readOnly={editable}
                            disablePortal
                            id="combo-box-demo"
                            options={optionsforState}
                            //onChange={handleChangeMUI}
                            onChange={(event, newValue: any) => {
                                setInputs((values: any) => ({ ...values, stateId: newValue != null ? newValue.OptionID : '' }));
                                setError((prevErrors) => ({ ...prevErrors, stateId: '' }));
                                setVisibleUpdate(false);
                            }}
                            getOptionLabel={(option) => option.OptionName}
                            getOptionKey={(option) => option.OptionID}
                            sx={{ width: 180, overflowY: 'auto', overflowX: 'hidden', marginRight: 1 }}
                            value={valueofState}
                            renderInput={(params) => (
                                <TextField
                                    error={!!error.stateId}
                                    {...params}
                                    label="State"
                                    variant="standard"
                                    name="stateId"
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: 'red',

                                        },
                                    }}

                                />

                            )}
                        />
                        {error.countryId ? <FormHelperText sx={{ color: 'red', fontSize: 9,maxWidth: '210px' }}>{error.stateId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.stateId}</FormHelperText>}
                    </FormControl>

                    

                    <button disabled={visibleUPdate} className={`btn123 ${disable || editable ? 'inactive' : ''} ${visibleUPdate ? 'btndisable' :'' }`}  onClick={(event) => { (editRowData as any) != '' ?  handelSubmit(event, true) : handelSubmit(event,false);  }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}</button>&nbsp;
    
                    <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => {setIssave(true); handelSubmit(event,true);  }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>
                        
                
                {/* </form> */}

                <button className={`btn123 ${disable ? '' : 'inactive'}`} onClick={() => {
                    setVisibleUpdate(false);
                    setDisable(false); setEditable(false);
                    (editRowData as any) = "";
                    setInputs({});
                    setUse(false);

                }} style={{ paddingRight: 12 }}><Add />&nbsp;Add</button>&nbsp;
                &nbsp;
                <button className={`btn123 ${disable ? 'inactive' : ''}  `} onClick={() => { setShowConfirmation(true); }}  ><Close />&nbsp;Close</button>


                {showConfirmation && (
                    <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                        <div className="confirmation-modal">
                            <p>Are you sure you want to exit? <br />(Any unsaved changes will be lost.)</p>
                            <div className="canel_btns">
                            <button className="btn123" style={{marginRight: 10}} onClick={handleClose}>Cancel</button>
                            <button className="btn123" style={{marginLeft:10}} onClick={handleConfirmExit}>Yes, Exit</button>
                            </div>
                        </div>
                    </Popup>

                )}
            </div>


        </div>
    </>);



}

export default AddCity;


