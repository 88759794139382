import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { userServicer } from "../../../Commen_Services/user.services";



interface propsHead {
    sendDataToParent: any;
    formData: any;
    sendErrorToParent: any;
    errorData: any;
    isViewData: boolean;
}
const PersonalInfo: React.FC<propsHead> = ({sendDataToParent, formData, sendErrorToParent, errorData, isViewData}) => {

    const [inputs, setInputs] = useState<any>({});
    const [error,setError] = useState<any>({});
    // const [error, setError] = useState({
    //     personal_Contact: '',
    //     personal_Email: '',
    //     birth_Date: '',
    //     gender: '',
    //     bloodGroup: '',
    //     maritalStatus: '',
    //     anniversaryDate: '',
    //     emergency_Contact_P_Name: '',
    //     emp_Relationship: '',
    //     emergency_Email: '',
    //     emergency_Contact: '',
    //     streetAddress: '',
    //     state_Id: '',
    //     city_Id: '',
    //     pinCodeId: '',
    //     highest_Education: '',
    //     institue_Name: '',
    // });
    const [birthDate, setBirthDate] = useState<any>("");
    const [anniversaryDate, setAnniversaryDate] = useState<any>("");
    const [selectedGender, setGender] = useState(null);
    const [selectedBloodGroup, setBloodGroup] = useState(null);
    const [selectedMarriedStatus, setMarriedStatus] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [optionsforState, setOptionsforState] = useState([{ OptionID: 1, OptionName: "--Select State--" }]);
    const [optionCity, setOptionCity] = useState([{ OptionID: 1, OptionName: "--Select City--" }]);
    const [optionPincode, setOptionPincode] = useState([{ OptionID: 1, OptionName: "--Select Pincode--" }]);
    const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
    const marriedOption = [{ OptionID: true, OptionName: "Married" },{ OptionID: false, OptionName: "Unmarried" }]
    const handleChangeforBirthDate = ((event: any) => {
        
        const name = event.target.name;
        const value = event.target.value;
        if (value > new Date().toISOString().split('T')[0]) {
            setError((prevErrors : any) => ({ ...prevErrors, birth_Date: 'Please enter a valid Birth date (not in the future).' }));
        } else {
            setBirthDate(value);
            setInputs((values: any) => ({ ...values, [name]: value }));
            setError((prevErrors: any) => ({ ...prevErrors, birth_Date: '' }));
        }

    });
    const [options, setOptions] = useState([{ OptionID: 1, OptionName: 'Male' }, { OptionID: 2, OptionName: 'Female' }, { OptionID: 3, OptionName: 'Other' }]);
    const handleChangeMUIforGender = (event: any) => {


        const name = event.target.name;
        const value = event.target.value;
        setGender(value);
        setInputs((values: any) => ({ ...values, [name]: value }));
    }
    const handleChangeforAnniversaryDate = ((event: any) => {
        
        const name = event.target.name;
        const value = event.target.value;
        if (value > new Date().toISOString().split('T')[0]) {
            setError((prevErrors: any) => ({ ...prevErrors, anniversaryDate: 'Please enter a valid Anniversary date (not in the future).' }));
        } else {
            setAnniversaryDate(value);
            setInputs((values: any) => ({ ...values, [name]: value }));
            setError((prevErrors: any) => ({ ...prevErrors, anniversaryDate: '' }));
        }

    });

    const handleChangeMUIforBloodGroup = (event: any) => {


        const name = event.target.name;
        const value = event.target.value;
        setBloodGroup(value);
        setInputs((values: any) => ({ ...values, [name]: value }));
    }

    const handleChangeMUIforMarritalStatus = (event: any) => {

        
        const name = event.target.name;
        const value = event.target.value;
        setMarriedStatus(value);
        setInputs((values: any) => ({ ...values, [name]: value }));
    }
    const handleChangeMUIforState = (event: any) => {

        if(event === null)
        {
            setSelectedState(event);
            setOptionCity([{ OptionID: 1, OptionName: "--Select City--" }]);
        }
        else
        {
            setSelectedState(event);
            setError((prevErrors: any) => ({ ...prevErrors, stateId: '' }));
        }
       
        //  setInputs((values: any ) => ({ ...values, [name]: value }))
    }
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let newInputs;
        newInputs = { ...inputs, [name]: value };

        let newError = { ...error };
        if(name === 'personal_Contact') {
           
            
            if(value !== "" && !/^[0-9]{10}$/.test(value))
            {
                newError.personal_Contact = "Please enter a valid Mobile Number";
            }
            else
            {
                newError.personal_Contact = '';
            }
        }
        if (name === 'personal_Email') {

            if ( value !== "" &&  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                newError.personal_Email = "Please enter a valid Email ";
            }
            else {
                newError.personal_Email = '';
            }
        }
        if(name === 'emergency_Contact_P_Name')
        {
            if ( value !== "" &&  !/^[a-zA-Z0-9 ]{2,100}$/.test(value)) {
                newError.emergency_Contact_P_Name = "Please enter a valid name ";
            }
            else {
                newError.emergency_Contact_P_Name = '';
            }
        }
        if (name === 'emergency_Email') {

            if (value !== "" &&  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                newError.emergency_Email = "Please enter a valid Email ";
            }
            else {
                newError.emergency_Email = '';
            }
        }
        if(name === 'emergency_Contact') {
            if( value !== "" && !/^[0-9]{10}$/.test(value))
            {
                newError.emergency_Contact = "Please enter a valid Mobile Number";
            }
            else
            {
                newError.emergency_Contact = '';
            }
        }


        setInputs(newInputs);
        setError(newError);

    };

    let pincode = { OptionName: '' };
    let stateId = { OptionName: '' };
    let cityId = { OptionName: '' };
    const getAutoCompleteValue = (optionModule: any, fieldName: any) => {
        let result = { OptionName: '' };
        if (optionModule !== undefined && optionModule !== null && inputs[fieldName] !== undefined && inputs[fieldName] !== null) {
            let val2 = optionModule.filter((item: any) => item.OptionID === inputs[fieldName]);

            let val4 = val2.length > 0 ? Object.values(val2[0]) : '';

            result.OptionName = val4[1] !== undefined ? String(val4[1]) : '';

        }
        return result;
    }

    pincode = getAutoCompleteValue(optionPincode, 'pinCodeId');
    stateId = getAutoCompleteValue(optionsforState, "state_Id");
    cityId = getAutoCompleteValue(optionCity, "city_Id");

    useEffect(() => {
        const fetcOption = async () => {
            try {
                const data = await userServicer.getDropDownlist("StateMaster", 104);
                const pincode = await userServicer.getDropDownlist("GetPincode", null);
                const h = data.data;
                const p = pincode.data;
                const transformedData = h.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(h[0])[0]],
                    OptionName: item[Object.keys(h[0])[1]] === undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
                }));

                const transFormPincode = p.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(p[0])[0]],
                    OptionName: item[Object.keys(p[0])[1]] === undefined ? item[Object.keys(p[0])[0]] : item[Object.keys(p[0])[1]]
                }));

                // console.log(transFormPincode);

                setOptionsforState(transformedData);
                setOptionPincode(transFormPincode);

            }
            catch {
                console.log("Error");

            }

        }

        fetcOption();


    }, [])

    useEffect(() => {
        const fetchState = async () => {


            let cityList1 = await userServicer.getDropDownlist("GetCity", Number((selectedState as any).OptionID))

            let cityList = cityList1.data;
            const transformedData1 = cityList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(cityList[0])[0]],
                OptionName: item[Object.keys(cityList[0])[1]] === undefined ? item[Object.keys(cityList[0])[2]] : item[Object.keys(cityList[0])[1]]
            }));

            setOptionCity(transformedData1);


        }


        if (!!selectedState) {
            fetchState();
        }
    }, [selectedState])

    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
          };
        let isDifferent = areObjectsEqual(inputs,formData);
        if(!isDifferent)
        {
            sendDataToParent(inputs);
        }
        // sendDataToParent(inputs);
       

    },[inputs])

    useEffect(() => {
        sendErrorToParent(error);
    },[error])

    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
          };

        let isDifferent = areObjectsEqual(error,errorData);
       
        
         
        if(!isDifferent)
        {
            // setInputsforChild(inputs);
                setError(errorData);  
        } 

    },[errorData]);

    useEffect(() => {
       
        const fetchCityByStateID = async (id: any) => {
            let cityList1 = await userServicer.getDropDownlist("GetCity", Number(id))

            let cityList = cityList1.data;
            const transformedData1 = cityList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(cityList[0])[0]],
                OptionName: item[Object.keys(cityList[0])[1]] === undefined ? item[Object.keys(cityList[0])[2]] : item[Object.keys(cityList[0])[1]]
            }));

            setOptionCity(transformedData1);
        }
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
          };

        let isDifferent = areObjectsEqual(inputs,formData);
          
        if(!isDifferent)
        {
            // setInputsforChild(inputs);
                setInputs(formData);
                
            if(formData.state_Id !== null && formData.state_Id !== undefined)
            {
                fetchCityByStateID(formData.state_Id);
            }    
            if(formData.birth_Date !== null && formData.birth_Date !== undefined)
            {
                
                const formattedDate = formData.birth_Date.split('T')[0];
                setBirthDate(formattedDate);
            }
            if(formData.anniversaryDate !== null && formData.anniversaryDate !== undefined)
            {
                
                
                const formattedDate = formData.anniversaryDate.split('T')[0];
                setAnniversaryDate(formattedDate);
            }
            if(formData.gender !== null)
            {
                setGender(formData.gender);
            }
            if(formData.maritalStatus !== null)
            {
                setMarriedStatus(formData.maritalStatus);
            }
            if(formData.bloodGroup !== null)
            {
                setBloodGroup(formData.bloodGroup)
            }
                
        } 
        
        // if(formData != inputs)
        // {
        //     setInputs(formData);
        //    // console.log(formData);
        // }
        

    },[formData])
    return (
        <>
            <div className="personal_Info">
                <div>Personal Informartion</div>
                {/* <hr /> */}
                <div>
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                type="number"
                                sx={{"input::-webkit-inner-spin-button" : {
                                    "-webkit-appearance": "none",
                                    "margin": "0"
                                }}}
                                name="personal_Contact"
                                value={inputs.personal_Contact}
                                label="Personal Contact no"
                                focused={!!inputs.personal_Contact}
                                onKeyDown={(evt) => ["e", "E", "+", "-",".","/"].includes(evt.key) && evt.preventDefault()}
                                onChange={handleChange}
                                error={!!error.personal_Contact}
                                helperText={error.personal_Contact}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.personal_Contact ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.personal_Contact ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.personal_Contact ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="personal_Email"
                                value={inputs.personal_Email}
                                label="Personal Email"
                                focused={!!inputs.personal_Email}
                                error={!!error.personal_Email}
                                helperText={error.personal_Email}
                                onChange={handleChange}
                                InputProps={{
                                    //readOnly: editable,
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.personal_Email ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.personal_Email ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.personal_Email ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name="birth_Date"
                                type="date"
                                value={birthDate}
                                label="Birth Date"
                                onChange={handleChangeforBirthDate}
                                focused={!!inputs.birth_Date}
                                error={!!error.birth_Date}
                                helperText={error.birth_Date}
                                InputProps={{
                                    inputProps: {
                                        className: birthDate ? '' : 'custom-date-input', 
                                      },
                                    readOnly: isViewData,
                                    style: {
                                        color: birthDate ? '#243C5C' : 'transparent',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.birth_Date ? 'red' : undefined
                                    },
                                    onInput: (event: any) => {

                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value })
                                            )

                                        }
                                    }
                                }}

                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.birth_Date ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.birth_Date ? '9px' : '12px',
                                        maxWidth: '210px'

                                    },
                                }}
                            />



                        </div>
                        <div className="profile_grid_c">
                            <FormControl focused={!!inputs.gender} variant="standard" sx={{ width: 200, minWidth: 120, }}>
                                <InputLabel id="demo-simple-select-standard-label" sx={{
                                    fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                                }}>Gender</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    autoComplete="inside"
                                    name="gender"
                                    readOnly={isViewData}
                                    error={!!error.gender}
                                    value={selectedGender}
                                    onChange={handleChangeMUIforGender}
                                    label="Search c"
                                    onClose={(e) => e.preventDefault()}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {options.map((item: any) => (
                                        <MenuItem key={item.OptionID} value={item.OptionName}>
                                            {item.OptionName}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </div>
                        <div className="profile_grid_c">
                            <FormControl focused={!!inputs.bloodGroup} variant="standard" sx={{ width: 200, minWidth: 120, }}>
                                <InputLabel id="demo-simple-select-standard-label" sx={{
                                    fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                                }}>Blood Group</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    autoComplete="inside"
                                    name="bloodGroup"
                                    readOnly={isViewData}
                                    error={!!error.bloodGroup}
                                    value={selectedBloodGroup}
                                    onChange={handleChangeMUIforBloodGroup}
                                    label="Search c"
                                    onClose={(e) => e.preventDefault()}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {bloodGroups.map((item: any) => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </div>
                        <div className="profile_grid_c">
                            <FormControl focused={inputs.maritalStatus === null || inputs.maritalStatus === undefined ? false : true} variant="standard" sx={{ width: 200, minWidth: 120, }}>
                                <InputLabel id="demo-simple-select-standard-label" sx={{
                                    fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                                }}>Marital Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    autoComplete="inside"
                                    name="maritalStatus"
                                    error={!!error.maritalStatus}
                                    readOnly={isViewData}
                                    value={selectedMarriedStatus}
                                    onChange={handleChangeMUIforMarritalStatus}
                                    label="Search c"
                                    onClose={(e) => e.preventDefault()}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {marriedOption.map((item: any) => (
                                        <MenuItem key={item.OptionID} value={item.OptionID}>
                                            {item.OptionName}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name="anniversaryDate"
                                type="date"
                                value={anniversaryDate}
                                label="Anniversary Date"
                                onChange={handleChangeforAnniversaryDate}
                                focused={!!inputs.anniversaryDate}
                                error={!!error.anniversaryDate}
                                helperText={error.anniversaryDate}
                                InputProps={{
                                    inputProps: {
                                        className: anniversaryDate ? '' : 'custom-date-input', 
                                      },
                                    readOnly: isViewData,
                                    style: {
                                        display: selectedMarriedStatus || selectedMarriedStatus == null ? 'block' : 'none',
                                        color: anniversaryDate ? '#243C5C' : 'transparent',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.anniversaryDate ? 'red' : undefined
                                    },
                                    onInput: (event: any) => {

                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value })
                                            )

                                        }
                                    }
                                }}

                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.anniversaryDate ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.anniversaryDate ? '9px' : '12px',
                                        maxWidth: '210px'

                                    },
                                }}
                            />



                        </div>




                    </div>


                </div>
                <div style={{ marginTop: 30 }}>
                    Emergency Contact Information
                </div>
                {/* <hr /> */}
                <div>
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="emergency_Contact_P_Name"
                                value={inputs.emergency_Contact_P_Name}
                                label="Contact Person Name"
                                onChange={handleChange}
                                focused={!!inputs.emergency_Contact_P_Name}
                                error={!!error.emergency_Contact_P_Name}
                                helperText={error.emergency_Contact_P_Name}
                                InputProps={{
                                    //readOnly: editable,
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.emergency_Contact_P_Name ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.emergency_Contact_P_Name ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.emergency_Contact_P_Name ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="emp_Relationship"
                                value={inputs.emp_Relationship}
                                label="Relationship"
                                focused={!!inputs.emp_Relationship}
                                error={!!error.emp_Relationship}
                                helperText={error.emp_Relationship}
                                onChange={handleChange}
                                InputProps={{
                                    //readOnly: editable,
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.emp_Relationship ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.emp_Relationship ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.emp_Relationship ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="emergency_Email"
                                value={inputs.emergency_Email}
                                onChange={handleChange}
                                label="Email"
                                focused={!!inputs.emergency_Email}
                                error={!!error.emergency_Email}
                                helperText={error.emergency_Email}
                                InputProps={{
                                    //readOnly: editable,
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.emergency_Email ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.emergency_Email ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.emergency_Email ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                type="number"
                                name="emergency_Contact"
                                sx={{"input::-webkit-inner-spin-button" : {
                                    "-webkit-appearance": "none",
                                    "margin": "0"
                                }}}
                                value={inputs.emergency_Contact}
                                label="Contact no"
                                onChange={handleChange}
                                focused={!!inputs.emergency_Contact}
                                onKeyDown={(evt) => ["e", "E", "+", "-",".","/"].includes(evt.key) && evt.preventDefault()}
                                error={!!error.emergency_Contact}
                                helperText={error.emergency_Contact}
                                InputProps={{
                                    //readOnly: editable,
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.emergency_Contact ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.emergency_Contact ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.emergency_Contact ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 30 }}>
                    Address
                </div>
                {/* <hr /> */}
                <div>
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="streetAddress"
                                value={inputs.streetAddress}
                                label="Street Address"
                                onChange={handleChange}
                                focused={!!inputs.streetAddress}
                                error={!!error.streetAddress}
                                helperText={error.streetAddress}
                                InputProps={{
                                    //readOnly: editable,
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.streetAddress ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.streetAddress ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.streetAddress ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <FormControl >
                                <Autocomplete
                                    readOnly={isViewData}
                                    // disabled={disable}

                                    disablePortal
                                    id="combo-box-demo"
                                    options={optionsforState}
                                    value={stateId}
                                    //onChange={handleChangeMUIforState}
                                    onChange={(event, newValue: any) => {
                                        handleChangeMUIforState(newValue);
                                        //setVisibleUpdate(false);
                                        setInputs((values: any) => ({ ...values, pinCodeId: '' }));
                                        setInputs((values: any) => ({ ...values, state_Id: newValue != null ? newValue.OptionID : '' }));
                                        // setError((prevErrors) => ({ ...prevErrors, stateId: '' }));

                                    }}
                                    getOptionLabel={(option) => option.OptionName}
                                    getOptionKey={(option: any) => option.OptionID}
                                    sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden', }}


                                    renderInput={(params) => (
                                        <TextField
                                            error={!!error.state_Id}
                                            {...params}
                                            label="State"
                                            variant="standard"
                                            name="state_Id"
                                            InputLabelProps={{
                                                style: {
                                                    color: '#243C5C',
                                                    fontSize: '12px',
                                                    borderColor: 'red',

                                                },
                                            }}

                                        />

                                    )}
                                />
                                {/* {error.countryId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.stateId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.stateId}</FormHelperText>} */}
                            </FormControl>
                        </div>
                        <div className="profile_grid_c">
                            <FormControl  >
                                <Autocomplete
                                    readOnly={isViewData}
                                    //disabled={disable}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={optionCity}
                                    //onChange={handleChangeMUI}
                                    onChange={(event, newValue: any) => {
                                        //setVisibleUpdate(false);
                                        setInputs((values: any) => ({ ...values, city_Id: newValue != null ? newValue.OptionID : '' }));
                                        setInputs((values: any) => ({ ...values, pinCodeId: '' }));
                                        setError((prevErrors: any) => ({ ...prevErrors, city_Id: '' }));

                                    }}
                                    value={cityId}
                                    getOptionLabel={(option) => option.OptionName}
                                    getOptionKey={(option) => option.OptionID}
                                    sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden', }}


                                    renderInput={(params) => (
                                        <TextField
                                            error={!!error.city_Id}
                                            {...params}
                                            label="City"
                                            variant="standard"
                                            name="city_Id"

                                            InputLabelProps={{
                                                style: {
                                                    color: '#243C5C',
                                                    fontSize: '12px',
                                                    borderColor: 'red',

                                                },
                                            }}

                                        />

                                    )}
                                />
                                {error.state_Id ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.city_Id}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.city_Id}</FormHelperText>}
                            </FormControl>
                        </div>
                        <div className="profile_grid_c">
                            <FormControl  >
                                <Autocomplete
                                    readOnly={isViewData}
                                    //disabled={disable}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={optionPincode}
                                    //onChange={handleChangeMUI}
                                    onChange={(event, newValue: any) => {
                                        //setVisibleUpdate(false);
                                        setInputs((values: any) => ({ ...values, pinCodeId: newValue != null ? newValue.OptionID : '' }));
                                        setError((prevErrors: any) => ({ ...prevErrors, pinCodeId: '' }));

                                    }}
                                    value={pincode}
                                    getOptionLabel={(option) => option.OptionName}
                                    getOptionKey={(option) => option.OptionID}
                                    sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden', }}


                                    renderInput={(params) => (
                                        <TextField
                                            error={!!error.city_Id}
                                            {...params}
                                            label="ZIP code"
                                            variant="standard"
                                            name="pinCodeId"

                                            InputLabelProps={{
                                                style: {
                                                    color: '#243C5C',
                                                    fontSize: '12px',
                                                    borderColor: 'red',

                                                },
                                            }}

                                        />

                                    )}
                                />
                                {error.pinCodeId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.city_Id}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.pinCodeId}</FormHelperText>}
                            </FormControl>
                        </div>

                    </div>
                </div>

                <div style={{ marginTop: 30 }}>
                    Education Details
                </div>
                {/* <hr /> */}
                <div>
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="highest_Education"
                                value={inputs.highest_Education}
                                onChange={handleChange}
                                label="Highest Education"
                                focused={!!inputs.highest_Education}
                                error={!!error.highest_Education}
                                helperText={error.highest_Education}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.highest_Education ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.highest_Education ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.highest_Education ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="institue_Name"
                                value={inputs.institue_Name}
                                label="Institute Name"
                                focused={!!inputs.institue_Name}
                                onChange={handleChange}
                                error={!!error.institue_Name}
                                helperText={error.institue_Name}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.institue_Name ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.institue_Name ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.institue_Name ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default PersonalInfo;