import { Add, Close, Save } from "@mui/icons-material";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import 'react-toastify/dist/ReactToastify.css';

import customToast from "../../Commen_Component/Toast/CustomToast";
import { userServicer } from "../../Commen_Services/user.services";
import { stateMasterService } from "../../Commen_Services/StateMaster.service";
import Popup from "reactjs-popup";

interface propsHead {
    columndata: any[];
    editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;

}

interface RowData {
    [key: string]: any;
}



const AddState: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {

    const [inputs, setInputs] = useState<any>({});
    const [inputsforEdit, setInputsforedit] = useState<any>({});

    const [disable, setDisable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [error, setError] = useState({
        state_Name: '',
        state_Code: '',
        countryId: ''
    });
    const [use, setUse] = useState(true);
    const [isSave, setIssave] = useState(false);
    const [options, setOptions] = useState([]);
    const [handleCancel, setCancel] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [visibleUPdate, setVisibleUpdate] = useState(false);







    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;
        // setInputs(values => ({...values, [name]: value}))
        let newInputs;
        let newError = { ...error };
        if (name === 'countryId') {
            const value1 = parseInt(value);
            newInputs = { ...inputs, [name]: value1 };
            if (value1 > 100) {
                newError.countryId = "Maximum of 2 digits";
            }
            else if (value1 < 0) {
                newError.countryId = "LevelId must be positive";
            }
            else {
                newError.countryId = '';
            }
        }
        else {
            newInputs = { ...inputs, [name]: value };
        }



        if (name === 'state_Name') {
            if (!/^[a-zA-Z0-9]{1,50}$/.test(value)) {
                newError.state_Name = "Please enter a unique alphanumeric State Name & Maximum length of 50 characters";
            }
            else if (value.trim() === '') {
                newError.state_Name = "Blank space not allowed";
            }
            else {
                newError.state_Name = '';
            }
        }
        if (name === 'state_Code') {

            if (!/^[a-zA-Z0-9]{1,10}$/.test(value)) {
                newError.state_Code = "Please enter a unique alphanumeric State Code & Maximum length of 10 characters";
            }
            else if (value.trim() === '') {
                newError.state_Name = "Blank space not allowed";
            }
            else {
                newError.state_Code = '';
            }
        }

        // Update state
        setInputs(newInputs);
        setError(newError);
        setVisibleUpdate(false);

    };


    const handleFocus = (fieldName: any) => {
        if (!inputs[fieldName]) {

            if (fieldName == "state_Code") {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric State Code" }));

            }

            if (fieldName == "state_Name") {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric State Name" }));

            }
        }
    };

    // const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    const handelSubmit = (event: any, isSav: any) => {
        event.preventDefault();


        if (!inputs.state_Name) {
            setError((prevErrors) => ({ ...prevErrors, state_Name: "Please enter a unique alphanumeric State Name" }));
        }
        if (!inputs.state_Code) {
            setError((prevErrors) => ({ ...prevErrors, state_Code: "Please enter a unique alphanumeric State Code" }));
        }
        if (!inputs.countryId) {
            setError((prevErrors) => ({ ...prevErrors, countryId: "Please Select Country" }));
        }

        if (inputs.state_Name !== undefined && inputs.state_Code !== undefined && inputs.countryId !== undefined) {
            if (error.state_Name === '' && error.state_Code === "" && error.countryId === "") {
                try {

                    // console.log(inputs);


                    stateMasterService.SaveorUpdate(inputs).then(user => {

                        if (user.statusCode === 200) {
                            if (user.status === false) {
                                customToast.Warning(user.message);
                                closeForm(isSav);
                                refreshGrid(true);
                            }
                            else {
                                customToast.success(user.message);
                                closeForm(isSav);
                                refreshGrid(true);

                            }
                            //customToast.showToast("Warning: Something went wrong", "warning");

                            // setInputs({});
                            // setDisable(true);
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }



        }


    }

    const handleChangeMUI = (event: any) => {

        const name = event.target.name;
        const value = event.target.value;
        // console.log(name + ":" + value);
        setError((prevErrors) => ({ ...prevErrors, countryId: '' }));
        setInputs((values: any) => ({ ...values, [name]: value }))
        setVisibleUpdate(false);

        //setSelectedField(values => ({ ...values, [name]: value }));
    }

    const closeForm = (isSav: any) => {

        setInputs({ state_Id: 0, isActive: true, state_Code: '', state_Name: '', countryId: '', isViewData: false });
        setError({
            state_Code: '',
            state_Name: '',
            countryId: ''
        });
        sendDatatoParent("");
        sendViewStatus(false);

        if (isSav) {
            setDisable(true);
            setEditable(true);
        }

    }

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        setIssave(true);
        setShowConfirmation(false);
        handleCancel ? setCancel(false) : setCancel(true);


    };

    useEffect(() => {


        const fetchStateById = async (state_ID: Number) => {
            let data = await stateMasterService.getstatedatabyid(state_ID);
            editRowData = data.data;
            // console.log(data.data);
            // console.log(editRowData);



            setInputs(editRowData);

        }

        setInputs((values: any) => ({ ...values, state_Id: 0, isActive: true }));
        if ((editRowData as any) !== '') {
            // console.log(editRowData, 'editRowData')

            fetchStateById((editRowData as any).state_Id);
            // setInputs(editRowData);
            setVisibleUpdate(true);
            setDisable(false);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }



    }, [editRowData, use])

    // useEffect(() => {
    //     closeForm();
    // }, [isSave]);

    useEffect(() => {

        if ((editRowData as any) !== '') {
            // console.log(editRowData);

            setInputsforedit(editRowData);
        }


    }, [editRowData])

    useEffect(() => {

        closeForm(true);


    }, [handleCancel])

    useEffect(() => {
        const fetcOption = async () => {

            try {
                const data = await userServicer.getDropDownlist("Country", null);
                const optionData = data.data;
                const transformedData = optionData.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(optionData[0])[0]],
                    OptionName: item[Object.keys(optionData[0])[1]] === undefined ? item[Object.keys(optionData[0])[0]] : item[Object.keys(optionData[0])[1]]
                }));
                setOptions(transformedData);
            }
            catch {
                console.log("Error while fetching dropdownlist");


            }

        }

        fetcOption();


    }, [])




    return (<>
        <div style={{ marginLeft: 10, marginBottom: -5 }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <form onSubmit={handelSubmit} style={{ display: 'flex', flexDirection: 'row' }} > */}


                <TextField

                    sx={{ marginRight: 1 }}
                    id="standard-basic"
                    variant="standard"
                    name="state_Code"
                    value={inputs["state_Code"] ? inputs["state_Code"] : ''}
                    label="State Code"
                    disabled={disable}
                    onChange={handleChange}
                    focused={!!inputs.state_Code}
                    onFocus={() => handleFocus("state_Code")}
                    error={!!error.state_Code}
                    helperText={error.state_Code}
                    InputProps={{
                        readOnly: editable,
                        style: {
                            color: '#243C5C',
                            fontSize: '14px',
                            width: '200px',
                            borderColor: error.state_Code ? 'red' : undefined
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#243C5C',
                            fontSize: '12px',
                            borderColor: error.state_Code ? 'red' : undefined
                        },
                    }}
                    FormHelperTextProps={{
                        style: {
                            fontSize: error.state_Code ? '9px' : '12px',
                            maxWidth: '210px',

                        },
                    }}
                />
                <TextField
                    sx={{ marginRight: 1 }}
                    id="standard-basic"
                    variant="standard"
                    name="state_Name"
                    disabled={disable}
                    value={inputs["state_Name"] ? inputs["state_Name"] : ''}
                    label="State Name"
                    onChange={handleChange}
                    focused={!!inputs.state_Name}
                    onFocus={() => handleFocus('state_Name')}
                    error={!!error.state_Name}
                    helperText={error.state_Name}
                    InputProps={{
                        readOnly: editable,
                        style: {
                            color: '#243C5C',
                            fontSize: '14px',
                            width: '200px',
                            borderColor: error.state_Name ? 'red' : undefined
                        }
                    }}
                    InputLabelProps={{

                        style: {
                            color: '#243C5C',
                            fontSize: '12px',
                            borderColor: error.state_Name ? 'red' : undefined
                        },
                    }}

                    FormHelperTextProps={{
                        style: {
                            fontSize: error.state_Name ? '9px' : '12px',
                            maxWidth: '210px',

                        },
                    }}

                />

                <FormControl variant="standard"
                    //  error={!!error.countryId} 
                    sx={{ m: -0.3, width: 180, minWidth: 120, marginRight: 1 }}>
                    <InputLabel id="demo-simple-select-standard-label" sx={{
                        fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                    }}>Country Name</InputLabel>
                    <Select

                        disabled={disable}
                        readOnly={editable}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        autoComplete="inside"
                        name="countryId"
                        error={!!error.countryId}
                        value={inputs?.countryId ? Number(inputs.countryId) : ''}
                        onChange={handleChangeMUI}
                        label="Search c"
                        onClose={(e) => e.preventDefault()}

                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                    overflowY: 'auto',
                                },
                            },
                        }}
                    >
                        {options.map((item: any) => (
                            <MenuItem key={item.OptionID} value={item.OptionID}>
                                {item.OptionName}
                            </MenuItem>
                        ))}
                    </Select>
                    {error.countryId ? (
                        <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.countryId}</FormHelperText>
                    ) : (
                        <FormHelperText sx={{ color: 'white', fontSize: 9 }}>Helper Text</FormHelperText>
                    )}
                </FormControl>


                <button disabled={visibleUPdate} className={`btn123 ${disable || editable ? 'inactive' : ''} ${visibleUPdate ? 'btndisable' : ''}`} onClick={(event) => { (editRowData as any) != '' ? handelSubmit(event, true) : handelSubmit(event, false); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}</button>&nbsp;

                {/* <button className={`btn123 ${disable || editable ? 'inactive' : ''}`} type="submit" style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) !== '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}</button>&nbsp; */}
                {/* <button className={`btn123 ${disable || editable || (editRowData as any) !== "" ? 'inactive' : ''}`} onClick={(event) => { handelSubmit(event); setIssave(true) }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button> */}
                <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => { setIssave(true); handelSubmit(event, true); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>

                {/* </form> */}

                <button className={`btn123 ${disable ? '' : 'inactive'}`} onClick={() => {
                    setDisable(false); setEditable(false);
                    setVisibleUpdate(false);
                    (editRowData as any) = "";
                    setInputs({});
                    setUse(false);

                }} style={{ paddingRight: 12 }}><Add />&nbsp;Add</button>&nbsp;
                &nbsp;
                {/* <button className={`btn123 ${disable ? 'inactive' : ''}`} onClick={() => { setIssave(true); closeForm() }}  ><Close />&nbsp; Close</button> */}
                {/* <button className={`btn123 ${disable ? 'inactive' : ''}`} onClick={() => { setIssave(true); closeForm() }}  ><Close />&nbsp;Close</button> */}
                <button className={`btn123 ${disable ? 'inactive' : ''}  `} onClick={() => { setShowConfirmation(true) }}  ><Close />&nbsp;Close</button>
                {showConfirmation && (
                    <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                        <div className="confirmation-modal">
                            <p>Are you sure you want to exit? <br />(Any unsaved changes will be lost.)</p>
                            <div className="canel_btns">
                                <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
                            </div>
                        </div>
                    </Popup>

                )}


            </div>


        </div>
    </>);



}

export default AddState;


